.rs-modal.add-section-modal {
  .rs-modal-content {
    background: var(--surface-color);
    border: 1px solid var(--border-color);
    border-radius: 8px;
    
    .rs-modal-header {
      border-bottom: 1px solid var(--border-color);
      padding: 1rem;
      
      .rs-modal-title {
        color: var(--text-primary-color);
        font-size: 1.2rem;
        font-weight: 500;
      }
    }
    
    .rs-modal-body {
      padding: 1.5rem 1rem;
    }
    
    .rs-modal-footer {
      border-top: 1px solid var(--border-color);
      padding: 1rem;
      display: flex;
      justify-content: flex-end;
      gap: 0.5rem;
    }
  }
} 