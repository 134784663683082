.offers-container {
  height: calc(100vh - var(--header-height));
  position: relative;
  display: flex;
  flex-direction: column;
  background: #000000;
  overflow: hidden;
}

#tsparticles {
  position: absolute !important;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100% !important;
  height: 100% !important;
  z-index: 1;
  pointer-events: none;
}

.drag-drop-context {
  position: relative;
  z-index: 2;
  flex: 1;
  min-height: 0;
  display: flex;
  background: rgba(0, 0, 0, 0.3);
}

.panel-group {
  flex: 1;
  display: flex;
  min-height: 0;
}

.panel-right {
  height: 100%;
  min-height: 0 !important;
  flex: 1 0 50% !important;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.panel-left {
  height: 100%;
  min-height: 0 !important;
  flex: 1 0 50% !important;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.offers-drop-zone {
  padding: 16px 16px 12px 16px;
  display: flex;
  flex-direction: column;
  flex: 1;
  min-height: 0;
  height: 100%;
  overflow: auto;
}

.offers-content-wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
  min-height: 0;
}

.offers-sections-list {
  display: flex;
  flex-direction: column;
  flex: 1;
  min-height: 0;
  overflow-y: auto;
  padding: 8px;
  gap: 8px;
  background: #1a1d24;
  max-height: calc(100% - 56px);
}

.list-section--expanded {
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  min-height: 0;
  margin: 0;
}

.list-section__content {
  flex: 1;
  overflow-y: auto;
  min-height: 0;
}

.offers-add-section-btn {
  flex: 0 0 auto;
  margin: 8px;
  background: #1a1d24;
}

.list-buttons-container {
  display: flex;
  gap: 12px;
  padding-top: 12px;

  > button {
    flex: 1;
    min-width: 0;
  }
}