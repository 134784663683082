.right-pane {
  height: 100%;
  display: flex;
  flex-direction: column;
  min-height: 0;
  overflow: hidden;
}

.all-offers-container {
  flex: 1;
  display: flex;
  flex-direction: column;
  min-height: 0;
  overflow: hidden;
  height: 100%;

  .all-offers-settings-container {
    padding: 16px 12px 6px 12px;
    background: #242830;
    border-bottom: 1px solid #2c303a;
    display: flex;
    flex-direction: column;

    .search-container {
      margin-bottom: 8px;
      position: relative;
      box-sizing: border-box;
      width: 100%;

      .search-input {
        width: 100%;
        height: 36px;
        background: rgba(0, 0, 0, 0.15);
        border: 1px solid #2c303a;
        padding: 0 36px 0 12px;
        color: #e4e6eb;
        font-size: 14px;
        transition: all 0.2s ease;
        box-sizing: border-box;

        &:hover {
          border-color: #343842;
        }

        &:focus {
          border-color: #4a4e5c;
          outline: none;
          background: rgba(0, 0, 0, 0.2);
        }

        &::placeholder {
          color: #8a8d91;
        }
      }

      .clear-button {
        position: absolute;
        right: 8px;
        top: 50%;
        transform: translateY(-50%);
        width: 20px;
        height: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #8a8d91;
        cursor: pointer;
        transition: color 0.2s ease;
        background: none;
        border: none;
        padding: 0;
        
        &:hover {
          color: #e4e6eb;
        }

        &:focus {
          outline: none;
        }
      }
    }
  }

  .all-offers-list {
    flex: 1;
    overflow-y: auto;
    padding: 16px 16px 0px 16px;
    
    // Modern scrollbar styling
    scrollbar-width: thin; // Firefox
    scrollbar-color: rgba(255, 255, 255, 0.2) transparent; // Firefox
    
    // Webkit browsers (Chrome, Safari, Edge)
    &::-webkit-scrollbar {
      width: 8px;
      height: 8px;
    }
    
    &::-webkit-scrollbar-track {
      background: transparent;
    }
    
    &::-webkit-scrollbar-thumb {
      background-color: rgba(255, 255, 255, 0.2);
      border-radius: 4px;
      border: 2px solid transparent;
      background-clip: padding-box;
      
      &:hover {
        background-color: rgba(255, 255, 255, 0.3);
      }
    }
    
    // Hide scrollbar when not hovering
    &:not(:hover)::-webkit-scrollbar-thumb {
      background-color: rgba(255, 255, 255, 0.1);
    }
  }

  .controls-row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 8px;

    .rs-radio-group {
      > .rs-radio {
        margin-left: 0;
      }
    }

    .rs-radio {
      svg {
        margin-right: 4px;
        transform: translateY(0.5px);
      }
    }

    .expand-all-button {
      background: none;
      border: none;
      color: #8a8d91;
      cursor: pointer;
      padding: 4px 8px;
      display: flex;
      align-items: center;
      justify-content: center;
      transition: color 0.2s ease;
      font-size: 14px;
      transform: translateY(1px);

      &:hover {
        color: #e4e6eb;
      }

      &:focus {
        outline: none;
      }
    }
  }
}

.all-country-section {
  margin-bottom: 16px;
  background-color: #1a1d24;
  border-radius: 4px 4px 0 0;
}

.all-country-title,
.partners-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 4px 8px;
  background: #242830;
  cursor: pointer;
  position: relative;
  border-radius: 4px;
  height: 36px;
  box-sizing: border-box;

  &:hover {
    background: #2c303a;
  }

  .country-header,
  .partners-header {
    flex: 1;
  }

  .section-expand-icon {
    color: #8a8d91;
    font-size: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.2s ease;
    cursor: pointer;
    padding: 4px;
    margin-right: 4px;

    &:hover {
      color: #e4e6eb;
    }
  }
}

.partner-section {
  margin-bottom: 20px;
}

.partner-title {
  font-weight: bold;
  cursor: pointer;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
}

.spoiler-symbol {
  margin-left: 8px;
  font-size: 0.8em;
  color: #8a8d91;
}

.all-offer-item {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.offer-logo {
  position: relative;
  width: 48px;
  height: 48px;
  background-color: #ccc;
  margin-right: 10px;
}

.offer-details {
  display: flex;
  flex-direction: column;
}

.partner-program {
  font-size: 0.9em;
  color: #8a8d91;
}

.offer-thumbnail-fg {
  position: absolute;
  top: 0;
  left: 0;
  width: 48px !important;
  height: 48px !important;
}

.offer-thumbnail-bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 48px;
  height: 48px;
}

.section-expand-button,
.partners-spoiler,
.countries-spoiler {
  display: none;
}

.partners-view,
.countries-view {
  width: 100%;
}