.rs-uploader-draggable .rs-uploader-trigger-customize .rs-uploader-trigger-btn {
    text-align: center;
}

.drag_modal_wrap{
    width: 100%;
    height: 100%;
    width: 100%;
    height: 100%;
    background-color: #0000009c;
    position: absolute;
    z-index: 1080;
    display: flex;
    align-items: center;
    justify-content: center;
  
  .drag_modal{
    z-index: 2000;
    width: 50%;



  
  }
  }
  
  