.offer-small {
    margin: 8px;
    display: flex;
    flex-direction: row;
    color: black;
    box-shadow: 0 0 8px 2px rgba(52, 195, 255, 0.3);
    background-color: white;
    border-radius: 10px;
    transition: 0.1s;
    cursor: pointer;
}

.offer-small:hover {
    scale: 0.95;
    box-shadow: 0 0 8px 4px rgba(52, 195, 255, 0.4);
}

.offer-small-center {
    flex-grow: 1;
    padding-top: 2px;
    font-weight: 600;
    padding-left: 6px;
    display: flex;
    flex-direction: column;
}

.offer-small-center-title {}

.offer-small-center-subtitle {
    color: gray;
    font-weight: 400;
    font-size: smaller;
}

.offer-small-thumbnail {
    position: relative;
    background-color: black;
    width: 80px;
    height: 80px;
    border-radius: 6px 0px 0px 6px;
    overflow: hidden;
}

.offer-small-thumbnail-fg {
    position: absolute;
    top: 0;
    left: 0;
    padding: 5px;
    width: 70px !important;
    height: 70px !important;
}

.offer-small-thumbnail-bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 80px;
    height: 80px;
}

.offer-small-end {
    align-self: center;
    padding-right: 6px;
    padding-left: 12px;
    height: 100%;
    display: flex;
    flex-direction: column;
}

.offer-country-container {
    display: flex;
    padding-top: 3px;
    flex-direction: column;
    align-items: start;
    justify-content: center;
}

.offer-country {
    font-size: smaller;
}

.offer-end-space {
    flex: 1;
}

.offer-id {
    color: gray;
}

.offer-country-sign {}