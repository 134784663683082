.stub-params-container {
  display: flex;
  padding: 20px;
  flex-direction: column;

  .title {
    text-align: center;
    margin-bottom: 10px;
    font-size: 15px;
    font-weight: 600;
  }

  .content_container {
    display: flex;
    justify-content: space-around;
    margin-top: 20px;
    width: 100%;
  }

  .left-section {
    flex: 0 0 40%;
    display: flex;
    flex-direction: column;
    gap: 15px;
    max-width: 40%;
  }

  .right-section {
    flex: 0 0 60%;
    display: flex;
    flex-direction: column;
    max-width: 55%;
    .title {
        text-align: center;
        margin-bottom: 10px;
        font-size: 14px;
        font-weight: 400;
      }
  }

  .input-block {
    margin-bottom: 15px;
    .title{
        font-size: 14px;
        font-weight: 400;   
        text-align: center;
    }

    label {
      display: block;
      margin-bottom: 5px;
    }

    input {
      width: 100%;
    }
  }

  .images-uploader{
    display: flex;
    flex-direction: column;
   align-items: center;
   width: 100%;
    .rs-uploader-trigger{
      margin-top: 10px;
      
    }
    .rs-uploader-file-items{
      max-height: 250px;
      width: 100%;
      overflow: auto;
    }
  }

  .images-block {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-top: 10px;
    width: 100%;
    .container{
      display: flex;
      justify-content: center;
      align-items: center;
      .title{
        font-size: 15px;
        font-weight: 500;
        text-align: center;
        margin-right: 25%;
        margin-bottom: 0;
      }
      .add-button{
        background-color: transparent;
        border: none;
        cursor: pointer;
        .add-icon{
          width: 20px;
          height: 20px;
          fill: var(--rs-blue-500);
        }
      }
      
    }
  }

  .right-section{
    section{
     border-radius: 5px;
     overflow: hidden;
    }
  }

  .policy-block{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-top: 15px;

    .policy-uploader{
      width: 100%;
    }

    .rs-uploader-trigger-btn{
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;

      
    }

    .policy-icon{
      cursor: pointer;
      width: 30px;
      height: 30px;
      margin-left: 20px;
    }
  }
}

.custom-tree{

 
    .tree-image{
      width: 100px;
      height: 100px;
      object-fit: cover;
    }

    .rs-tree-node-expanded.rs-tree-node-children>.rs-tree-group{
      display: flex;
   
          padding: 0;
          justify-content: space-around;
          align-items: center;
          width: 100%;
   
  
      .tree-node{
        display: flex;
        flex-direction: column;
        background-color: #1e1e1e;
        padding: 5px;
        box-sizing: border-box;
        border-radius: 10px;
      }
    }
}
.trash-icon{
  width: 40%;
  height: 40%;
  cursor: pointer;
  display: none;
}
.tree-label{

  font-size: 100%;
  font-weight: 400;
}
.image-overlay{
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 85px;
  height: 85px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50px;
 

  &:hover{
    background-color: #0000006b;
    .trash-icon{
      display: block ;
      fill: red;
    }
  }
 

}

.tree-icon-folder{
  margin-right: 10px;
}

.folder-conatainer{
  position: absolute;
 
  width: 100%;
  height: 100%;
  input{
   opacity: 0;
  }
}
