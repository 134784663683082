
:root{
  --rs-bg-card: #1a1d24;
  --rs-gray-700: #292d33;
}

.auth_form_container {
background-color: var(--rs-bg-card);
    position: absolute;
  width: 90%;
  display: flex;
  box-sizing: border-box;
  justify-content: center;
  transform: translate(-50%, -50%);
    left: 50%;
    top: 50%;
    padding: 25px 15px 25px 15px;
    max-width: 700px;
}
.auth_form {
  display: flex;
  flex-direction: column;
  width: inherit;
  align-items: center;
}
.form_control {
  height: 45px;
  border-radius: 102.185px;
  border: none;
  width: 90%;
  margin-top: 20px;
  background-color: var(--rs-gray-700);
  color: white;
  font-size: 13pt;
  text-align: start;
  padding: 0px 15px 0px 15px;

}
.btn_submit {
  min-width: 155px;
  box-sizing: border-box;
  padding: 10px;
  border-radius: 102.185px;
  background-color: rgb(52, 152, 255);
  margin-top: 15px;
  font-style: normal;
  font-weight: 700;
  font-size: 19.9385px;
  line-height: 24px;
}
