.domain_wrap_archived{
    width: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    .list{
        width: 100%;
        display: flex;
        align-items: start;
        padding-left: 0;
        list-style-type: none;
        flex-direction: column;
        .list_item{
            padding: 5px;
        }
    }
}

.flex_title{
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
}