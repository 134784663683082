.offers {
  padding-top: 6px;
  display: flex;
  flex-flow: column;
  height: calc(100vh - 56px);
}

.partners-countries-checkbox {
  margin-left: -10px;
}

.partners-section {
  flex: none;
}

.partners-layout {
  height: 100%;
}

.partners-section-content {
  height: calc(100% - 2.5rem);
  display: flex;
  margin-top: 6px;
  flex-direction: row;
  overflow-x: scroll;
  padding-left: 12px;
}

.offers-column {
  display: flex;
  flex-direction: column;
  padding-bottom: 12px;
  height: calc(100% - 1.6rem);
  overflow-y: scroll;
}

.offers-column::-webkit-scrollbar {
  display: none;
}

.partners-section-geo {
  display: flex;
  padding-top: 6px;
  flex-direction: column;
}

.partners-section-geo-title {
  font-size: medium;
  margin-left: 12px;
  margin-right: 12px;
  text-align: end;
}

.partners-countries-checkbox {
  height: 2.5rem;
  margin-left: 12px;
}

.show-hidden-checkbox {
  height: 2.5rem;
  margin-left: 12px;
}

.show-active-checkbox {
  height: 2.5rem;
  margin-left: 12px;
}

.partners-country-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: end;
  padding-right: 6px;
}

.partners-country {
  font-size: medium;
  padding-right: 6px;
}

// .partners-country-sign {}

.groupByGroup {
  flex-direction: row;
  margin: 12px 20px 12px 20px;
}