.app-json {
  min-height: 500px;
  height: auto;
  padding: 20px;
  margin-bottom: 20px;
  
  // Стилі для контейнера Monaco Editor
  .monaco-editor {
    border-radius: 6px;
    overflow: visible;
    margin: 0;
    background-color: #1e1e1e;
    height: 100% !important;
  }
} 