.offers-list {
  display: flex;
  flex-direction: column;
  gap: 12px;
  min-height: 0;
  overflow-y: auto;
  overflow-x: hidden;
  height: 100%;
  padding-top: 0;

  &:has(.reorder-mode) {
    gap: 0;

    .section-wrapper:not(.expanded) {
      min-height: 48px;
    }
  }

  // Droppable wrapper
  > div {
    display: flex;
    flex-direction: column;
    min-height: 0;
  }

  .section-wrapper {
    position: relative;
    transition: max-height 0.3s cubic-bezier(0.4, 0, 0.2, 1);
    overflow: hidden;
    
    &:not(.expanded) {
      overflow: visible;
      min-height: 36px;
    }

    &.dragging-offer {
      [data-rbd-draggable-context-id] {
        background: none !important;
        transform: none !important;
        transition: none !important;
      }
    }
  }

  // [data-rbd-draggable-context-id] {
  //   transform: translate(0, 0);
  // }

  [data-rbd-drag-handle-draggable-id] {
    cursor: grab !important;

    &:active {
      cursor: grabbing !important;
    }
  }
}

.offers-drop-zone {
  padding: 8px 12px 12px 12px;
  display: flex;
  flex-direction: column;
  flex: 1;
  min-height: 0;
  height: 100%;
  overflow: auto;
}

.section-dragging {
  opacity: 0.8;
  background: transparent;
}

[data-rbd-placeholder-context-id] {
  background: transparent !important;
  opacity: 0;
}

.offers-list-item {
  display: flex;
  align-items: center;
  padding: 0 7px 0 0;
  cursor: grab;
  user-select: none;
  width: 100%;
  height: 48px;
  box-sizing: border-box;
  transition: background-color 0.2s ease;
  background: #242830;
  
  &:hover {
    background: #2c303a;
  }
  
  &:active {
    cursor: grabbing;
    background: #343842;
  }
  
  &__logo {
    position: relative;
    width: 48px;
    height: 48px;
    flex-shrink: 0;
    margin-right: 10px;
  }
  
  &__details {
    display: flex;
    flex-direction: column;
    min-width: 0;
    flex: 1;
    gap: 2px;
  }
  
  &__name {
    font-size: 0.9rem;
    font-weight: 500;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: #e4e6eb;
  }
  
  &__partner {
    font-size: 0.8rem;
    color: #b0b3b8;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  
  &__thumbnail {
    position: absolute;
    top: 0;
    left: 0;
    width: 48px;
    height: 48px;
    object-fit: cover;
    
    &--bg {
      z-index: 1;
    }
    
    &--fg {
      z-index: 2;
    }
  }

  &__actions {
    display: flex;
    gap: 8px;
    margin-left: 8px;
    align-items: center;
    z-index: 1;
  }

  &__icon {
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.2s ease;
    cursor: pointer;
    color: #8a8d91;
    position: relative;

    &:hover {
      color: #e4e6eb;
    }

    &--eye {
      &.hidden {
        color: #ffb700;
        
        .eye-slash {
          opacity: 1;
        }
      }

      .eye-slash {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        opacity: 0;
        transition: opacity 0.2s ease;
        color: #ffb700;
      }
    }

    &--trash {
      position: relative;
      transition: transform 2s ease, color 2s ease;
      color: #8a8d91;

      &.holding {
        transform: scale(1.4);
        color: #ff4d4f;
      }

      .trash-icon {
        transition: all 0.2s ease;
      }
    }
  }

  // Prevent drag when interacting with icons
  &__icon * {
    pointer-events: none;
  }

  transition: all 0.2s ease;
  
  &.removing {
    opacity: 0;
    transform: scale(0.9);
  }

  &[data-rbd-draggable-context-id] {
    background: #242830;
  }
} 