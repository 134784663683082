.add_domain_modal {
  .add_btn_wrap {
    justify-content: end;
    width: 100%;
    display: flex;
  }
}
.add_btn_wrap {
  width: 94%;
  display: flex;
  justify-content: space-between;
}

.domain_wrap {
  display: flex;
  flex-direction: column;
  align-items: center;
  .err_message {
    color: red;
    padding: 5px;
    display: none;
  }
   .input_custom_wrap{
    width: 100%;
    position: relative;

    .hover_error {
        position: absolute;
   top: 50%;
   right: 4px;
   top: 17px;
   transform: translateY(-50%);
   display: flex;
   align-items: center;
   justify-content: center;
     display: flex;
     align-items: center;
     justify-content: center;
     &:hover {
       .err_message {
         display: block;
         position: absolute;
         top: 50%;
         transform: translateY(-50%);
         background-color: #352f2f;
         right: 0;
         border-radius: 10px;
         width: 150px;
         text-align: center;

       }
     }

     .error_icon {
       width: 25px;
       height: 25px;
       fill: red;
     }
   }
}



  .text_area_custom_wrap {
    width: 100%;
    position: relative;
    margin-bottom: 20px;
    .hover_error {
         position: absolute;
    top: 50%;
    right: 4px;
    top: 17px;
    transform: translateY(-50%);
    display: flex;
    align-items: center;
    justify-content: center;
      display: flex;
      align-items: center;
      justify-content: center;
      &:hover {
        .err_message {
          display: block;
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          background-color: #352f2f;
          right: 0;
          border-radius: 10px;
          width: 150px;
          text-align: center;

        }
      }

      .error_icon {
        width: 25px;
        height: 25px;
        fill: red;
      }
    }
   
  }

  .err_icon {
    position: absolute;
    top: 50%;
    right: 5px;
    transform: translateY(-50%);
    width: 25px;
    height: 25px;
    padding: 0px;
  }
  .custom_ip_input {
    margin-bottom: 0;
  }
  .ip_input{
      min-height: 30px;
      box-sizing: border-box;
      resize: none;
      outline: none;
      background-color: var(--rs-input-bg);
      border: 1px solid var(--rs-border-primary);
      border-radius: 6px;
      color: var(--rs-text-primary);
      display: block;
      font-size: 14px;
      line-height: 24px;
      padding: 7px 45px 7px 11px;
      transition: border-color 0.15s ease-in-out;
      width: 100%;
      min-height: auto;
      overflow-y: hidden;
  }
}
.input_error {
  border: solid 1px red;
  outline: red;
  &:focus,
  &:hover:not(:disabled) {
    border-color: red;
  }

}

.result_container{
  height: 250px;
  min-height: 220px;
    display: flex;
    flex-direction: column;
    justify-content: end;
    align-items: center;

    .rs-btn.rs-btn-primary.rs-btn-green{
      padding: 10px 5px;
    }

  
  .list_wrap{
    height: 100%;
    width: 100%;
    align-items: start;
    display: flex;
    padding-top: 35px;
    
  }
  .result_list{
    list-style-type: none;
    display: flex;
    flex-direction: column;
    align-items: start;
    .list_item{
      margin-bottom: 15px;
      display: flex;
      align-items: center;
      justify-content: center;
      .result_text{
        font-size: 110%;
        margin-left: 15px;
      }
    }
  }
}
