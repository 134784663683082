.domains {
    padding-top: 6px;
    display: flex;
    align-items: center;
    width: 100%;
    padding: 0px 20px;
    box-sizing: border-box;
    flex-flow: column;
    overflow: hidden;
}

.header {
    padding: 12px 12px 12px 12px;
    align-items: center;
    justify-content: end;
    width: 100%;
    display: flex;
    flex-direction: row;

    .button_tool_bar {
        display: flex;

        .rs-dropdown-open.rs-dropdown-placement-bottom-start>.rs-dropdown-menu,
        .rs-dropdown-open.rs-dropdown-placement-top-start>.rs-dropdown-menu {
            height: auto;
        }
    }
}

.balance {
    font-size: 1rem;
    font-size: 20px;
    flex: 1;
}

.balance-number {
    color: var(--rs-navbar-default-selected-text);
    font-weight: 700;
}

.domain-search {
    width: 30vw;
    margin-left: 12px;

    .rs-input-group-addon {
        padding: 11px 12px;
    }
}

.button-buy-domains {
    margin-right: 12px;
    margin-left: 15px;
}

.rs-message-error .rs-message-container {
    background-color: #a7171a !important;
}

.rs-modal-wrapper {
    display: flex;
    align-items: center;
}

@media screen and (max-width: 900px) {
    .header {
        padding: 5% 5%;

        .rs-dropdown {
            // display: none;
        }

        flex-direction: column;

        .button_tool_bar {
            padding: 7% 5%;
            width: 100%;
            justify-content: space-around;
            flex-direction: row-reverse;

            .button-buy-domains {
                margin: 0;
            }
        }
    }

    .rs-dropdown-toggle,
    .rs-dropdown-toggle.rs-btn {
        display: flex;
    }
}

@media screen and (max-width: 480px) {
    .header {
        padding: 5% 5%;

        .button_tool_bar {
            .rs-dropdown {
                display: none;
            }
        }

    }
}