:root {
  --partners-border-radius: 6px;
}

.partners-list-container,
.partners-section,
.partners-header,
.partners-name,
.partners-offer-details,
.partners-offer-name,
.partners-country-name {
  user-select: none;
}

.partner-section {
  margin-bottom: 20px;
}

.partner-title {
  font-weight: bold;
  cursor: pointer;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
}

.partners-spoiler {
  color: #8a8d91;
  font-size: 1rem;
  transition: transform 0.2s ease;
  transform: rotate(-90deg);
}

.partners-title[data-expanded="true"] {
  .partners-spoiler {
    transform: rotate(0deg);
  }
}

.partners-offer-item {
  display: flex;
  align-items: center;
  padding: 0 12px 0 0;
  cursor: grab;
  user-select: none;
  width: 100%;
  height: 48px;
  box-sizing: border-box;
  background-color: #1a1d24;

  transition: background-color 0.2s ease;

  &:last-child {
    border-bottom-left-radius: var(--partners-border-radius);
    border-bottom-right-radius: var(--partners-border-radius);

    .partners-offer-logo {
      border-bottom-left-radius: var(--partners-border-radius);
    }

    .partners-offer-thumbnail-fg,
    .partners-offer-thumbnail-bg {
      border-bottom-left-radius: var(--partners-border-radius);
    }
  }

  &:not(:last-child) {
    border-bottom: 1px solid #2c303a;
  }

  &:hover {
    background: #2c303a;
  }

  &:active {
    cursor: grabbing;
    background: #343842;
  }

  &--disabled {
    opacity: 0.6;
    cursor: not-allowed;

    .partners-offer-name {
      font-weight: 300;
    }
  }

  &__section {
    font-size: 0.8rem;
    color: #8a8d91;
    margin-left: auto;
  }
}

.partners-offer-logo {
  position: relative;
  width: 48px;
  height: 48px;
  flex-shrink: 0;
  background-color: #242830;
  margin-right: 10px;
}

.partners-offer-details {
  display: flex;
  flex-direction: column;
  min-width: 0;
  flex: 1;
  gap: 4px;
}

.partners-offer-name {
  font-weight: bold;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.partners-country-name {
  font-size: 0.9em;
  color: #8a8d91;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.partners-offer-thumbnail-fg,
.partners-offer-thumbnail-bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 48px !important;
  height: 48px !important;
}

.partners-section {
  margin-bottom: 12px;
  border: 1px solid #2c303a;
  border-radius: var(--partners-border-radius);
  overflow: hidden;
  width: 100%;
  min-width: 300px;
  display: flex;
  flex-direction: column;
  background: #1a1d24;

  &[data-expanded="true"] {
    flex: 1;
    min-height: 0;
  }

  .partners-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 8px 0 8px 12px;
    cursor: pointer;
    user-select: none;
    height: 36px;
    box-sizing: border-box;
    background: #242830;
    transition: background-color 0.2s ease;

    &:hover {
      background: #2c303a;
    }

    &[data-expanded="true"] {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
      background: #343842;

      &+.partners-list-container {
        display: flex;
      }
    }
  }

  .partners-header {
    display: flex;
    align-items: center;
    gap: 8px;
  }

  .header-indicators {
    display: flex;
    gap: 4px;
    margin-left: 12px;
    margin-right: 4px;
    align-items: center;

    .offer-indicator {
      width: 4px;
      height: 4px;
      border-radius: 50%;
      background: #8a8d91;
      flex-shrink: 0;

      &--in-section {
        background: #4caf50;
      }

      &--hidden {
        background: #ffb700;
      }

      &:not(:first-child) {
        position: relative;

        &::before {
          content: '';
          position: absolute;
          left: -3px;
          top: 50%;
          transform: translateY(-50%);
          width: 2px;
          height: 8px;
          background: #2c303a;
        }
      }
    }
  }

  .offer-item-indicator {
    width: 6px;
    height: 6px;
    border-radius: 50%;
    margin-right: 4px;
    flex-shrink: 0;

    &--in-section {
      background: #4caf50;
    }

    &--hidden {
      background: #ffb700;
    }
  }

  .partners-name {
    font-size: 0.9rem;
    font-weight: 500;
    color: #e4e6eb;
  }

  .partners-spoiler {
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: transform 0.2s ease;
    color: #8a8d91;
    font-size: 16px;
  }

  &[data-expanded="true"] {
    .partners-spoiler::after {
      transform: rotate(0);
    }
  }

  .partners-list-container {
    display: none;
    flex-direction: column;
    gap: 1px;
    padding: 1px;
    background: #1a1d24;
    border-top: none;
    overflow-y: auto;
    flex: 1;
    min-height: 0;
    overflow-y: auto;

    // Modern scrollbar styling
    scrollbar-width: thin; // Firefox
    scrollbar-color: rgba(255, 255, 255, 0.2) transparent; // Firefox

    // Webkit browsers (Chrome, Safari, Edge)
    &::-webkit-scrollbar {
      width: 8px;
      height: 8px;
    }

    &::-webkit-scrollbar-track {
      background: transparent;
    }

    &::-webkit-scrollbar-thumb {
      background-color: rgba(255, 255, 255, 0.2);
      border-radius: 4px;
      border: 2px solid transparent;
      background-clip: padding-box;

      &:hover {
        background-color: rgba(255, 255, 255, 0.3);
      }
    }

    // Hide scrollbar when not hovering
    &:not(:hover)::-webkit-scrollbar-thumb {
      background-color: rgba(255, 255, 255, 0.1);
    }
  }
}

.partners-subgroup-header {
  padding: 4px 8px;
  font-size: 12px;
  font-weight: bold;
  color: #e4e6eb;
  background: #242830;
  display: flex;
  align-items: center;
  gap: 8px;

  .offer-indicators {
    display: flex;
    gap: 4px;
    margin-left: auto;
  }

  .offer-indicator {
    width: 6px;
    height: 6px;
    border-radius: 50%;
    background: #8a8d91;
    flex-shrink: 0;

    &--in-section {
      background: #4caf50;
    }

    &--hidden {
      background: #ffb700;
    }
  }
}

.partners-offer-section {
  font-size: 0.8rem;
  color: var(--text-secondary-color);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.section-expand-icon {
  margin-left: auto;
  margin-right: 0;
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #8a8d91;
  transform: rotate(-90deg);
  transition: transform 0.3s ease;
}

.partners-title[data-expanded="true"] {
  .section-expand-icon {
    transform: rotate(0deg);
  }
}

.partners-title[data-expanded="false"] {
  .section-expand-icon {
    transform: rotate(-90deg);
    transition: transform 0.3s ease;
  }
}