.list-section {
  border: 1px solid #2c303a;
  border-radius: 6px;
  overflow: hidden;
  width: 100%;
  min-width: 300px;
  display: flex;
  flex-direction: column;
  background: rgba(26, 29, 36, 0.8);
  box-sizing: border-box;
  position: relative;
  min-height: 36px;
  height: 36px;

  &.reorder-mode {
    animation: tremble 0.2s infinite;
    height: 36px !important;
    .list-section__title {
      color: white !important;
    }
    .section-icons .icon {
      opacity: 0 !important;
      pointer-events: none;
    }
  }

  &--expanded {
    height: 100%;
    flex-grow: 1;
  }

  &--expanded &__header {
    background: #343842;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
  
  &__header {
    background: #242830;
    height: 36px;
    box-sizing: border-box;
    cursor: pointer;
    user-select: none;
    display: flex;
    align-items: center;
    position: relative;
    z-index: 1;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.15);

    &:hover {
      background: #2c303a;
    }
  }

  &__title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 0.9rem;
    line-height: normal;
    font-weight: 500;
    color: #8a8d91;
    margin: 0;
    width: 100%;
    padding: 0 12px;

    &[data-expanded="true"] {
      padding-right: 10px;
      color: white;

      .section-icons {
        .icon {
          transform: rotate(0);
        }
      }
    }

    &--empty {
      color: #8a8d91;
    }

    .section-icons {
      display: flex;
      align-items: center;
      position: relative;
      width: 16px;
      height: 16px;

      .icon {
        position: absolute;
        width: 16px;
        height: 16px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #8a8d91;
        font-size: 10px;
        transform: rotate(-90deg);
        transition: transform 0.3s ease, color 0.2s ease;

        &:hover {
          color: #e4e6eb;
        }
      }

      .section-drag-handle {
        cursor: grab;
        opacity: 0;
        transform: rotate(-90deg);
        z-index: 1;
        font-size: 12px;

        &:hover {
          color: #e4e6eb;
        }

        &.visible {
          opacity: 1;
          transform: rotate(0);
          z-index: 2;
        }
      }
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    gap: 1px;
    padding: 1px;
    background: rgba(0, 0, 0, 0.3);
    border-top: none;
    overflow: hidden;
    flex: 1;
    min-height: 0;
    box-sizing: border-box;
    position: relative;
    transform: translateZ(0);
    scrollbar-width: thin;
    scrollbar-color: rgba(138, 141, 145, 0.3) transparent;

    &::-webkit-scrollbar {
      width: 8px;
      position: absolute;
      right: 0;
    }

    &::-webkit-scrollbar-track {
      background: transparent;
    }

    &::-webkit-scrollbar-thumb {
      background: rgba(138, 141, 145, 0.3);
      border-radius: 4px;
      
      &:hover {
        background: rgba(138, 141, 145, 0.5);
      }
    }

    .geo-handler-wrapper {
      top: 0;
      padding: 16px;
    }
  }

  .section-icons {
    .section-hamburger-icon,
    .section-collapse-icon {
      font-size: 10px;
      width: 16px;
      height: 16px;
    }

    .section-drag-handle {
      font-size: 10px;
      width: 16px;
      height: 16px;
    }
  }
}

.geo-handler-wrapper {
  opacity: 0;
  pointer-events: none;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 1000;
  backdrop-filter: blur(0);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 32px;
  transition: opacity 0.15s ease, backdrop-filter 0.15s ease;
  will-change: opacity, backdrop-filter;

  &.visible {
    opacity: 1;
    pointer-events: auto;
    backdrop-filter: blur(4px);
  }
}

.geo-group {
  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 4px 12px;
    background: #1a1d24;
    user-select: none;
    cursor: pointer;
    height: 28px;
    box-sizing: border-box;
    border-bottom: 1px solid #2c303a;

    &:hover {
      background: #242830;
    }
  }

  &__content {
    overflow: hidden;
    height: 0;
    box-sizing: border-box;
    
    &.expanded {
      height: auto;
      overflow-y: auto;
    }

    &-wrapper {
      display: flex;
      flex-direction: column;
      box-sizing: border-box;
    }
  }

  &__country {
    display: flex;
    align-items: center;
    gap: 8px;
    font-size: 0.8rem;
    color: #8a8d91;
  }

  &__name {
    font-weight: 500;
  }

  &__count {
    opacity: 0.7;
  }

  &__spoiler {
    color: #8a8d91;
    font-size: 1rem;
    transform: rotate(-90deg);

    &.expanded {
      transform: rotate(0);
    }
  }
}

@keyframes highlight-new-offer {
  0% {
    background-color: rgba(76, 175, 80, 0.2);
  }
  100% {
    background-color: transparent;
  }
}

@keyframes remove-offer {
  0% {
    opacity: 1;
    transform: scale(1);
  }
  100% {
    opacity: 0;
    transform: scale(0.9);
  }
}

.highlight-new {
  animation: highlight-new-offer 1s ease-out forwards;
}

.removing {
  animation: remove-offer 0.2s ease-out forwards;
}

@keyframes tremble {
  0% { transform: rotate(0deg); }
  25% { transform: rotate(-0.5deg); }
  75% { transform: rotate(0.5deg); }
  100% { transform: rotate(0deg); }
}

.offers-list {
  padding-top: 0;
}

.dragging {
  &.draggable-wrapper {
    position: relative;
    
    // Remove any margins/padding that could affect positioning
    margin: 0;
    padding: 0;
    
    // Ensure the dragged item maintains its dimensions
    width: var(--draggable-width);
    height: var(--draggable-height);
  }

  .offers-list-item {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    margin: 0;
    transform-origin: 50% 50%;
    pointer-events: none;
  }
}

.draggable-wrapper {
  position: relative;
  width: 100%;
} 