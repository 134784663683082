.note_modal {
    position: absolute;
    z-index: 2000;
    min-height: 20%;
    min-width: 40%;
    top: 50%;
    left: 50%;
    background-color: #0f131a;
    border-radius: 10px;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 10px;


    .text {
        margin-bottom: 20px;


    }

    .sub_text {
        color: var(--rs-navbar-default-selected-text);
    }

}

.note_icon {
    cursor: pointer;
    position: relative;
    width: 100%;
    height: 100%;
}





.table {
    height: 100% !important;


    padding: 0;

    .rs-table-cell-header-sortable .rs-table-cell-content {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: nowrap;
    }

    .rs-table-cell-content {
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 95%;
    }

    .date-cell {
        width: 100%;
        display: flex;
        align-items: center;
        text-align: center;
        justify-content: center;
    }





    .host-cel {
        display: flex;
        width: 100%;
        height: 100%;
        justify-content: center;
        font-size: clamp(12px, 2vw, 13.3px);


        .text {
            font-weight: 600;
            font-family: 'Inter', sans-serif;
        }
    }

    .custom_text {
        cursor: pointer;
        position: relative;
        color: var(--rs-navbar-default-selected-text);

        &:hover::after {
            content: "";
            width: 100%;
            height: 2px;
            background-color: var(--rs-navbar-default-selected-text);
            display: block;
            position: absolute;
            border-radius: 5px;
        }
    }

    .arhived_custom_container {}

    .expired_icon {
        fill: #b41105;
        width: 18%;
        height: 25%;
    }
}

.table .rs-table-cell-header-sortable .rs-table-cell-content {
    font-size: 90%;
}




.rs-table-body-row-wrapper {
    height: calc(100vh - 120px) !important;
}


.rs-dropdown-item-panel {
    padding: 5px;
}

.label_name {
    padding-left: 9px;
    margin-bottom: 3px;
}

.rs-dropdown-open.rs-dropdown-placement-bottom-start>.rs-dropdown-menu,
.rs-dropdown-open.rs-dropdown-placement-top-start>.rs-dropdown-menu {
    height: 120px;
    overflow: auto;
    scrollbar-width: none;
    scrollbar-color: var(--rs-navbar-default-selected-text) #2C2F33;
}

/* Стилізація всієї смуги прокрутки */
::-webkit-scrollbar {
    width: 8px;
    /* Ширина вертикальної смуги прокрутки */
    height: 8px;
    /* Висота горизонтальної смуги прокрутки */
}

/* Фон смуги прокрутки */
::-webkit-scrollbar-track {
    background: #2C2F33;
    /* Фон треку */
    border-radius: 10px;
    /* Додаємо закруглення треку */
}

/* Ручка смуги прокрутки */
::-webkit-scrollbar-thumb {
    background-color: #7289DA;
    /* Колір ручки прокрутки */
    border-radius: 10px;
    /* Додаємо закруглення ручки */
    border: 2px solid #2C2F33;
    /* Поле для додаткової "пухлості" ручки */
}

/* Стилізація кнопок (стрілок) на смузі прокрутки */
::-webkit-scrollbar-button {
    background-color: #2C2F33;
    /* Колір фону кнопок */
    display: block;
}
.reload_icon{
    width: 90%;
    height: 95%;
}


@media screen and (max-width: 900px) {
    .table {
        .host-cell {
            justify-content: left;
            padding-left: 0;
            padding-right: 0;

            .rs-table-cell-content {
                padding-left: 0;
                padding-right: 0;
            }

            .host-cel {
                justify-content: left;
            }

        }

        .expired_icon {
            fill: #b41105;
            width: 35%;
            height: 25%;
        }
    }

    .ip-cell .rs-table-cell-content {
        padding-right: 0;
        padding-left: 0;
        font-size: clamp(12px, 2vw, 13.3px);
        justify-content: left;
        .rs-table-cell-content {
            padding-left: 0;
            justify-content: start;
            font-size: 74%;
        }
    }

    .time_icon {
        width: 50%;
        height: 50%;
    }

}