.geo-group {

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 12px 8px;
    background-color: #242830;
    cursor: pointer;
    user-select: none;

    &:hover {
      background-color: #2c303a;
    }
  }

  &__country {
    display: flex;
    align-items: center;
    gap: 8px;
  }

  &__flag {
    border-radius: 2px;
  }

  &__name {
    font-size: 12px;
    font-weight: bold;
    color: #e4e6eb;
  }

  &__count {
    color: #8a8d91;
    font-size: 12px;
  }

  &__spoiler {
    font-size: 0.8em;
    color: #8a8d91;
    transition: transform 0.2s ease;
    display: inline-block;

    &.expanded {
      transform: rotate(270deg);
    }
  }

  &__content {
    overflow: hidden;
    height: 0;
    transition: height 0.2s ease;
    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none; /* IE and Edge */
    
    &::-webkit-scrollbar {
      display: none; /* Chrome, Safari and Opera */
    }

    &.expanded {
      overflow-y: auto;
    }

    &-wrapper {
      display: flex;
      flex-direction: column;
    }
  }
}

[data-rbd-draggable-context-id] {
  &.dragging {
    visibility: hidden !important;
    opacity: 0 !important;
    background: transparent !important;
  }
}

.dragging-portal {
  pointer-events: none;
  position: fixed;
  z-index: 9999;
  
  .offers-list-item {
    background: #242830;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
  }
}

.geo-group__content-wrapper {
  &.dragging-over {
    // Remove or comment out any background-color properties
  }
}

