.upsert-app-modal-container {
    display: flex;
    flex-direction: column;
    gap: 10px;


}

// Загальні стилі для всіх контейнерів форми
%form-container {
  margin-top: 20px;
  
  label {
    display: block;
    margin-bottom: 8px;
    font-weight: 500;
  }
}

.country-picker-container {
  @extend %form-container;

  .rs-picker-toggle {
    background-color: #f7f7fa;
  }
}

.integration-version-container {
  @extend %form-container;

  .rs-picker-toggle {
    background-color: #f7f7fa;
  }
}

.rs-modal-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  
  .app-status-toggle {
    margin-right: 35px;
  }
}

.rs-picker-select-menu-items {
  height: 150px ;
}


// Кастомний селектор для дропдауна версій інтеграції
.integration-version-dropdown {
  .rs-picker-select-menu {
    height: 150px !important;
    max-height: 150px !important;
    overflow-y: auto;
    
    .rs-picker-select-menu-items {
      height: 150px !important;
      max-height: 150px !important;
      
      &::-webkit-scrollbar {
        width: 4px;
      }
      
      &::-webkit-scrollbar-thumb {
        background: #8e8e93;
        border-radius: 2px;
      }
      
      &::-webkit-scrollbar-track {
        background: transparent;
      }
    }
  }
  .rs-picker-toggle-wrapper{
    padding: 5px 0 ;
  }
}
.rs-picker-popup{
    height: 150px ;
}

.modal-header-content {
  display: flex;
  align-items: center;
  width: 100%;
  padding-right: 35px;

  .rs-modal-title {
    display: flex;
    align-items: center;
    margin: 0;
    height: 32px;
    
    .app-id {
      margin-left: 10px;
      
      span {
        color: #3498ff;
        font-weight: 700;
      }
    }
  }

  .app-status-toggle {
    margin-left: auto;
  }
}

.tracking-url-container {
  @extend %form-container;
  
  .rs-input {
    width: 100%;
    background-color: #1a1d24;
    cursor: not-allowed;
    color: #33bffa;
    font-weight: 500;
    padding: 7px 11px;
    line-height: 1.4;
    resize: none;
    overflow-wrap: break-word;
    overflow: hidden;
    text-overflow: ellipsis;
    
    // Перевизначаємо стандартні стилі rsuite для інпута
    &.rs-input-readonly {
      white-space: pre-wrap;
      height: auto;
      min-height: 35px;
    }
  }
}

.edit_btn_wrap {
  margin-top: 25px;
  display: flex;
  justify-content: flex-end;
  gap: 10px;
}

.tag-container {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
}

.tag-item {
  display: flex;
  align-items: center;
  gap: 4px;
  background-color: rgb(60 63 67);
  padding: 0 8px;
  border-radius: 4px;
}

.tag-flag {
  width: 20px;
  height: 15px;
}

.tag-code {
  font-weight: bold;
  color: #fff;
}

.tag-remove {
  cursor: pointer;
  color: #fff;
  cursor: pointer;
  color: #a4a9b3;
  margin-left: 8px;
  width: 15px;
  margin-left: 8px;
}

.rs-picker-toggle-wrapper{
  // padding: 7px;
  width: 100%;
}

.password-modal{

  .rs-modal-title{
    margin-bottom: 10px;
  }
  .rs-modal-content{
    width: 70%;
  }
  .rs-modal-dialog{
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: center;
  }
}

.domain-name-container {
  margin-top: 20px;
  label{
    display: block;
    margin-bottom: 8px;
    font-weight: 500;
  }
}

.bundle-container{
  margin-top: 20px;
  label{
    display: block;
    margin-bottom: 8px;
    font-weight: 500;
  }
}

.general-container{
  display: flex;
      width: 100%;
      justify-content: space-between;
}

.general-container-left{
  width: 49%;
  display: flex;
  flex-direction: column;
  // gap: 10px;
  .title{
    font-size: 16px;
    font-weight: 500;
    text-align: center;
  }
}

.custom_upsert_modal {
  width: 90%;
  .rs-modal-content{
    height: 90vh;
  }

  .rs-modal-header .rs-modal-header-close{
     z-index: 1080;
     width: 18px;
     height: 18px;
     color: white;
     .rs-icon{
      width: 100%;
      height: 100%;
     }
   
  }

  .rs-modal-body{
   max-height: 88% !important;
  }
}

.edit_btn_wrap_upsert{
  padding: 5px;
  width: 100%;
  display: flex;
  justify-content: space-around;
  gap: 10px;
}

.gutter {
  background-color: #ccc;
  cursor: col-resize;
}