.rs-modal-wrapper {
  overflow: hidden;
}

.button_wrap {
  display: flex;
  width: 100%;
  justify-content: space-between;
}
.button_wrap_buy_domain{
  display: flex;
  width: 100%;
  justify-content: space-between;
}
.domain_list_container {
  scrollbar-width: none; 
    -ms-overflow-style: none;  
.title_bot{
    font-size: 19px;
    font-weight: 700;
    margin-bottom: 20px;
    text-align: center;
}

  display: flex;
  align-items: center;
  overflow: auto;
  justify-content: center;
  flex-direction: column;
  .total_price {
    padding-top: 20px;
    width: 100%;
    display: flex;
    justify-content: center;
    font-size: 20px;
    font-weight: 500;
  }
  .totalPrice_price {
    color: var(--rs-navbar-default-selected-text);
    font-size: 20px;
    font-weight: 500;
    margin-left: 5px;
  }
  .list_domain_wrap{
    margin-top: 20px;
    max-height: 350px;
    
    display: flex;
    flex-direction: column;
    align-items: start;
    width: 94%;

  }
  .list_domain_wrap::-webkit-scrollbar {
    display: none;
    width: 0;
    background: transparent;
  }
  .title {
    font-size: 15px;
    font-weight: 500;
  }
  .list {
    list-style: none;
    display: flex;
    flex-direction: column;
    align-items: start;
    padding: 10px;
    .ancor_list_item {

      cursor: pointer;
      .ancors{
        font-size: 15px;
        font-weight: 500;
        &:hover {
            color: var(--rs-navbar-default-selected-text);
        }
      }
    
    }

    .list_item {
      margin-bottom: 10px;
      margin-bottom: 10px;
    display: flex;
    align-items: center;
      &:last-child {
        margin-bottom: 0;
      }
      .active_domain_badge {
        margin-right: 10px;
        background-color: var(--rs-navbar-default-selected-text);
      }
      .inactive_domain_badge {
        margin-right: 10px;
      }
      .name {
        font-size: 15px;
        font-weight: 500;
      }
      .price {
        font-size: 15px;
        margin-left: 15px;
        font-weight: 500;
        color: var(--rs-navbar-default-selected-text);
      }
    }
  }
  .list_wrap {
    display: flex;
    align-items: start;
    flex-direction: column;
    width: 100%;

    .total {
      font-size: 19px;
      font-weight: 700;
      margin-bottom: 30px;
      margin-top: 20px;
      .price {
        margin-left: 5px;
        color: var(--rs-navbar-default-selected-text);
        font-size: 19px;
        font-weight: 700;
      }
    }
  }

}
.customize_disable{
  .rs-input:disabled{
    color: var(--rs-text-primary);
  }
}

.add_btn_wrap_buy{
  width: 94%;

}