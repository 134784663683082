.ip_input {
    min-height: 30px;
    box-sizing: border-box;
    resize: none;
    outline: none;
    background-color: var(--rs-input-bg);
    border: 1px solid var(--rs-border-primary);
    border-radius: 6px;
    color: var(--rs-text-primary);
    display: block;
    font-size: 14px;
    line-height: 24px;
    padding: 7px 45px 7px 11px;
    -webkit-transition: border-color .15s ease-in-out;
    transition: border-color .15s ease-in-out;
    width: 100%;
    min-height: auto;
    overflow-y: hidden;
    margin-top: 5px;


}

.custom_ip_input {
    resize: none;
    line-height: 24px;
    margin-bottom: 10px;
}

.input_label_wrap {
    width: 100%;

    .txt_label {
        padding: 3px;
    }
}

.rs-modal-body {
    /* Стилізація скролбару для всіх елементів */
    margin-top: 0;

    & {

        scrollbar-width: none;
        scrollbar-color: var(--rs-input-bg) var(--rs-bg-overlay);
        /* колір бару та колір треку */
    }

    /* Стилізація скролбару для Webkit-браузерів (Chrome, Safari) */
    &::-webkit-scrollbar {
        border-radius: 10px;
        width: 0;
    }

    &::-webkit-scrollbar-track {

        border-radius: 10px;
        background: var(--rs-bg-overlay)
    }

    &::-webkit-scrollbar-thumb {

        scrollbar-color: var(--rs-input-bg) var(--rs-bg-overlay);
        /* колір бару та колір треку */
        border-radius: 10px;
        background: var(--rs-bg-overlay)
    }

}

.txt_flex_container {
    display: flex;
    align-items: center;

    .text_area {}

    .rs-btn-primary {
        height: 35px;
    }

    .delete_btn {
        position: absolute;
        top: 50%;
        right: 0;
        transform: translateY(-50%);
        background-color: transparent;
        height: 100%;

        .trash_icon {
            width: 100%;
            width: 100%;
            height: 20px;
        }

        &:hover {
            .trash_icon {
                fill: #ce3131;
            }
        }
    }

    .laber_delete_wrap {
        position: relative;

    }
}

.add_btn_container {
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding-right: 15px;
    box-sizing: border-box;

    .proxy_container {
        display: flex;
        align-items: center;
        justify-content: end;

        .proxy_togle {
            width: 20%;
        }

    }

    .rs-btn-subtle {
        background-color: green;
        color: white;
    }

    .rs-dropdown-open.rs-dropdown-placement-bottom-start>.rs-dropdown-menu,
    .rs-dropdown-open.rs-dropdown-placement-top-start>.rs-dropdown-menu {
        height: auto;
    }

}

.rs-modal-footer .rs-btn+.rs-btn.save_button {
    padding: 1% 4%;
}

.rs-modal-footer {
    display: flex;
    width: 100%;
    justify-content: space-around;
    align-items: center;
}



.rs-modal-title {
    display: flex;

    .title {
        color: var(--rs-btn-primary-bg);
        font-size: 18px;
        font-weight: 400;
        line-height: 1.11111111;
        margin-left: 10px;
        cursor: pointer;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
}

.rs-dropdown-open.rs-dropdown-placement-bottom-start>.rs-dropdown-menu,
.rs-dropdown-open.rs-dropdown-placement-top-start>.rs-dropdown-menu {
    left: 50%;
    transform: translate(-50%);
}




.confim_text_delete {
    text-align: center;


}

.button_wrap {
    display: flex;
    width: 98%;
    justify-content: space-between;
    margin-top: 25px;
}



.rs-input-group {
    align-items: center;
    margin-right: 3%;
}

.input_picker_group {
    display: flex;
    align-items: center;
}

.custom_piker.rs-dropdown-open.rs-dropdown-placement-bottom-start>.rs-dropdown-menu,
.rs-dropdown-open.rs-dropdown-placement-top-start>.rs-dropdown-menu {
    position: fixed;
    left: auto;
    top: 60%;
    transform: translate(0);
    width: 97px;
    display: flex;
    flex-direction: column;
    align-items: center;

    .rs-dropdown-item {
        align-items: center;
        text-align: center;
    }

}

.custom_piker {

    .rs-dropdown-toggle,
    .rs-dropdown-toggle.rs-btn {
        padding: 10px 32px 10px 16px;
    }
}

.rs-input:disabled {
    background-color: var(--rs-input-bg);
    color: var(--rs-text-primary);

}


@media screen and (max-width: 900px) {

    .rs-modal-content {
        padding: 15px 10px;
    }

    .rs-modal-footer .rs-btn+.rs-btn.save_button {
        font-size: 90%;
    }

    .rs-btn-subtle {
        font-size: 90%;

    }

}