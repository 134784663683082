.drag-ghost {
  &__container {
    position: fixed;
    top: -1000px;
    left: -1000px;
    background: #1a1d24;
    border: 1px solid #2c303a;
    color: #e4e6eb;
    border-radius: 4px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
    z-index: 9999;
    pointer-events: none;
    display: none;
    width: auto;
    align-items: center;
    opacity: 1;
    transition: none;
    overflow: hidden;

    &--active {
      display: flex !important;
    }
  }

  &__logo {
    width: 48px;
    height: 48px;
    margin-right: 8px;
    position: relative;
    flex-shrink: 0;
    background: #242830;
  }

  &__thumbnail {
    width: 100%;
    height: 100%;
    position: absolute;

    &--bg {
      z-index: 1;
    }

    &--fg {
      z-index: 2;
    }
  }

  &__content {
    flex: 1;
    min-width: 0;
    // padding: 8px 12px;
    display: flex;
    flex-direction: column;
    gap: 4px;
  }

  &__name {
    font-weight: bold;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 14px;
    color: #e4e6eb;
  }

  &__partner {
    color: #8a8d91;
    font-size: 12px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &__country {
    display: flex;
    align-items: center;
    gap: 4px;
    padding: 8px 12px;
    flex-shrink: 0;
  }

  &__country-code {
    font-size: 12px;
    color: #b0b3b8;
  }

  &__section {
    font-size: 0.8rem;
    color: #8a8d91;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
} 