/* _apps.scss */
.apps {
    height: calc(100vh - 100px);
    padding: 20px;
    display: flex;
    flex-direction: column;
  
    .header {
      margin-bottom: 20px;
      box-sizing: border-box;
    }
  
    .table-row-clickable {
      &:hover {
        background-color: var(--rs-table-hover-bg);
      }
    }
    .create_new_app_btn{
      margin-right: 3%;
    }
  
    .app-list-item {
      display: block;
      width: 100%;
      height: 100%;
    }
    .app-modal-container {
      display: flex;
      flex-direction: column;
      gap: 10px;
    }
    .platform_badge {
      margin-right: 15px;
      cursor: pointer;
      &:hover{
        color: var(--rs-blue-500);
      }
    }
    .platforms_cell {
      display: flex;
      gap: 5px;
     
    }


    .app-list-item-container {
      display: flex;
      align-items: baseline;
      height: 100%;  
      .app-list-item-id-marker {
        color:var(--rs-blue-500);
        font-size: 120%;
        margin-right: 3px;

      }
      .app-list-item {
        font-size: 120%;
        color:var(--rs-blue-500);

      } 
      .app-list-item-integration-version {
        font-size: 100%;
      }
    }
    .app-list-item-integration-version{
      color:var(--rs-blue-500);
      
    }

    .rs-table-body-row-wrapper{
      height: auto;
    }
  }
  