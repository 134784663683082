.platforms-accordion {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 50%;
  height: 65vh;
  overflow: auto;

  .rs-navbar{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 99%;
    .rs-navbar-nav{
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
    }
  }
  .rs-panel {
    margin-bottom: 10px;
    background-color: inherit;
    border: none;
  }

  .rs-panel-header {
    background-color: inherit;
    cursor: pointer;
    padding: 10px;
  }

  .rs-panel-body {
    padding: 10px;
  }

  .section-title {
    font-size: 15px;
    margin-bottom: 15px;
  }

  .platform-buttons {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 20px;
    border: none;
  }

  .rs-btn {
    // margin: 5px;
    border: none;
  }

  .platform-details {
    width: 100%;
    margin: 0 auto;
    margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    padding: 20px 15px;
    background-color: #1a1d24;
    border: 1px solid var(--rs-border-primary);
    border-radius: 4px;
  }

  .platform-buttons {
    padding: 3px 15px;
    border: none;
  }
  .heder-platform-details {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #1a1d24;
    border-radius: 4px;
    width: 100%;
  }
  .wrap_platform-details {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  .proxy_container {
    display: flex;
    align-items: center;
    justify-content: end;
    margin-right: 20px;

    .proxy_togle {
      width: 40px;
      height: 40px;
    }
  }

  .platform_container {
    display: flex;
    align-items: center;
    justify-content: center;

    .proxy_budge {
      width: 20px;
      margin-left: 5px;
    }

    .keitaro {
      font-size: 10px;
      margin-left: 5px;
      background-color: #58b15b;
      width: 15px;
      height: 15px;
      text-align: center;
      border-radius: 10px;
      color: #fff;
    }
  }
}
.custom_tabs {
  padding: 5px 15px;
  height: 25px;
  border-radius: 5px;
  &:last-child {
    margin-right: 0 !important;
  }
}

.custom_tabs {
  padding: 10px;
  position: relative;
  cursor: pointer;
  transition: border-bottom 0.3s ease;
}

.custom_tabs.active {
  overflow: visible;
  &::after {
    content: "";
    width: 100%;
    height: 3px;
    background-color: #007bff;
    position: absolute;
    bottom: -6px;
    left: 50%;
    transform: translate(-50%);
    border-radius: 5px;
  }
}
.custom_badge.rs-badge-independent.rs-badge-dot,
.rs-badge-wrapper .rs-badge-content.rs-badge-content {
  width: 6px;
  height: 6px;
  right: 2px;
}

.custom_badge.rs-badge-yellow .rs-badge-content,
.rs-badge-yellow.rs-badge-independent {
  background-color: #6a6f76;
}


.platform_container{
  display: flex;
  align-items: center;
  justify-content: center;
  .proxy_budge {
    
    width: 20px;
margin-left: 5px;
  }
}

.platform-params {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 20px;
  width: 100%;

  .custom_country_picker {
     .rs-picker-toggle.rs-btn {
      width: 98% !important;
    }
    .rs-picker-tag .rs-picker-textbox .rs-picker-search-input, .rs-picker-tag-md .rs-picker-textbox .rs-picker-search-input{
      margin-top: 0;
    }
    .rs-picker-textbox{
      padding: 5px;
    }
    .rs-picker-tag .rs-picker-textbox, .rs-picker-tag-md .rs-picker-textbox{
      min-height: 50px;
    }
  }
}


.tag-container {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
}

.tag-item {
  display: flex;
  align-items: center;
  gap: 4px;
  background-color: rgb(60 63 67);
  padding: 0 8px;
  border-radius: 4px;
}

.tag-flag {
  width: 20px;
  height: 15px;
}

.tag-code {
  font-weight: bold;
  color: #fff;
}

.tag-remove {
  cursor: pointer;
  color: #fff;
  cursor: pointer;
  color: #a4a9b3;
  margin-left: 8px;
  width: 15px;
  margin-left: 8px;
}

.create-platform-interface{
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 90px;
}

.section-header {
  text-align: center;
  font-weight: bold;
  margin-top: 20px;
}

.toggle-container {
  display: flex;
  align-items: center;
  gap: 8px;
}

.toggle-label {
  font-size: 14px;
}

.new-platform-toggle {
  display: flex;
      width: 100%;
      justify-content: center;
  .toggle-label {
    margin-right: 5%;
  }
}

.custom_modal_aprove{
  .rs-modal-content{
    width: 90%;
  }
  .rs-modal-dialog{
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .rs-modal-body{
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
