body {
    font-family: 'Montserrat', sans-serif;
}

.noselect {
    -webkit-touch-callout: none;
    /* iOS Safari */
    -webkit-user-select: none;
    /* Safari */
    -khtml-user-select: none;
    /* Konqueror HTML */
    -moz-user-select: none;
    /* Old versions of Firefox */
    -ms-user-select: none;
    /* Internet Explorer/Edge */
    user-select: none;
    /* Non-prefixed version, currently
                                    supported by Chrome, Edge, Opera and Firefox */
}
.custom-file-container {
    opacity: 0;
    display: none;
}

@font-face {
    font-family: 'Inter';
    src: url('./ui/fonts/Inter/Inter-VariableFont_opsz\,wght.ttf') format('truetype');
    font-style: normal;
}