.rs-modal {
  .rs-modal-header {
    padding: 20px;
  }

  .rs-modal-body {
    padding: 20px;
  }

  .rs-modal-footer {
    padding: 20px;
  }
} 