:root {
  --header-height: 56px;
}

.split-layout {
  display: flex;
  width: 100%;
  height: 100%;
}

.left-pane{
  height: calc(100vh - var(--header-height));
}

.panel-group {
  height: 100%;
  width: 100%;
}

.panel {
  height: 100%;
}

.panel-left {
  height: calc(100vh - var(--header-height));
}

.panel-right {
  height: calc(100vh - var(--header-height));
}

.resize-handle {
  width: 4px;
  background: #2c303a;
} 