.app-info {
  display: flex;
  justify-content: center;
  min-height: 100vh;
  background-color: #1a1d24;
  padding: 20px;
  
  .content-wrapper {
    display: flex;
    gap: 40px;
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    
    .input-container {
      display: flex;
      gap: 10px;
      height: fit-content;
      
      .app-id-input {
        width: 300px;
        input {
          height: 40px;
          font-size: 16px;
        }
      }
    }

    .app-info-container {
      flex: 1;
      
      .root-geos {
        margin: 10px 0;
        color: #8e8e8e;
      }

      .platform-panel {
        margin-bottom: 15px;

        .platform-header {
          display: flex;
          justify-content: space-between;
          align-items: center;
          width: 100%;

          .platform-geos {
            font-size: 0.9em;
            color: #fff;
            padding: 0 10px;
          }
        }
      }

      .geo-buttons {
        margin: 15px 0;
        display: flex;
        flex-wrap: wrap;
        gap: 5px;

        .rs-btn.rs-btn-active, .rs-btn:active{
          background-color: #169de0;
        }
        .rs-btn{
          border-radius: 5px;
        }
      }

      .section-container {
        margin-top: 10px;
        display: flex;

        h4 {
          margin-bottom: 10px;
        }
        .section-offers {
          display: flex;
          width: 100%;
          flex-wrap: wrap;
          gap: 1px;
          justify-content: space-between;
        }
      }

      .offer-panel {
        margin-bottom: 10px;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        height: 200px;
        width: 100%;

        .rs-panel-btn{
          position: relative;
          z-index: 1;
        }
        .rs-panel-btn:active, .rs-panel-btn:focus, .rs-panel-btn:hover{
          background-color: #00000083;
        }
        .rs-panel-header+.rs-panel-body-collapse .rs-panel-body{
          background-color: #00000083;
          position: relative;
          z-index: 1;
          padding: 10px;
          border-radius: 5px;

        }
       
       
       
        p {
          margin: 5px 0;
        }
      }
    
    }
    .offer-logo {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 50%;
     

    }

    .offer-container {
      position: relative;
      width: 49%;
      .offer-text {
        color: #fff;
        color: #fff;
    font-size: 1rem;
    font-weight: 600;
      }
    }
  }
  
}


