.geo-handler-wrapper {
  position: absolute;
  inset: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  transition: opacity 0.15s ease;
  pointer-events: none;
  z-index: 100;
  padding: 20px;
  backdrop-filter: blur(4px);
  background: rgba(26, 29, 36, 0.5);

  &.visible {
    opacity: 1;
    pointer-events: auto;
  }
}

.geo-offer-handler {
  background: rgba(26, 29, 36, 1);
  backdrop-filter: blur(10px);
  padding: 24px;
  padding-top: 48px;
  border-radius: 8px;
  border: 2px dashed #2c303a;
  color: #e4e6eb;
  min-width: 300px;
  max-width: 600px;
  width: 100%;
  position: relative;
  overflow: visible;
  box-shadow: 0 8px 32px rgba(0, 0, 0, 0.32);

  .offers-list {
    position: relative;
    padding: 0;
    margin-bottom: 56px; // Space for country info
    min-height: 48px;
    display: flex;
    flex-direction: column;
    gap: 12px;
    pointer-events: none;
    overflow: visible;

    &.dragging-over {
      background: transparent;
    }
  }

  .offer-item {
    display: flex;
    padding: 0 12px 0 0;
    cursor: grab;
    user-select: none;
    width: 100%;
    flex-direction: row;
    height: 48px;
    box-sizing: border-box;
    transition: background-color 0.2s ease;
    background: #242830;
    border: 1px solid #2c303a;
    border-radius: 4px;
    margin: 0;
    position: relative;
    z-index: 1;
    pointer-events: none;

    &:hover {
      background: #2c303a;
    }
    
    &:active {
      cursor: grabbing;
      background: #343842;
    }

    &.dragging {
      background: #242830;
      box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
      transform: scale(1.02);
      z-index: 2;
    }

    .offer-logo {
      position: relative;
      width: 48px;
      height: 48px;
      flex-shrink: 0;
      margin-right: 10px;
      border-radius: 4px 0 0 4px;
      overflow: hidden;

      .offer-thumbnail-bg,
      .offer-thumbnail-fg {
        position: absolute;
        top: 0;
        left: 0;
        width: 48px;
        height: 48px;
        object-fit: cover;
      }

      .offer-thumbnail-bg {
        z-index: 1;
      }

      .offer-thumbnail-fg {
        z-index: 2;
      }
    }

    .offer-details {
      display: flex;
      flex-direction: column;
      min-width: 0;
      justify-content: center;
      flex: 1;
      gap: 2px;

      .offer-name {
        font-size: 0.9rem;
        font-weight: 500;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        color: #e4e6eb;
      }

      .offer-partner {
        font-size: 0.8rem;
        color: #8a8d91;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }

  .country-info {
    position: absolute;
    bottom: 16px;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    align-items: center;
    gap: 12px;
    padding: 8px 16px;
    background: rgba(26, 29, 36, 0.8);

    .country-flag {
      font-size: 24px !important;
      line-height: 24px !important;
    }

    .country-code {
      font-weight: 500;
      font-size: 16px;
      color: #e4e6eb;
      letter-spacing: 0.5px;
    }
  }

  .placeholder {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 240px;
    padding: 24px;
    position: relative;

    .plus-icon {
      font-size: 64px;
      color: #8a8d91;
      margin-bottom: 24px;
    }
  }
}

.dragging-over {
  background: #242830;
  border-radius: 8px;
}