:root {
  --country-border-radius: 6px;
  --countries-border-radius: 6px;
}

.country-offers-container,
.countries-list-container,
.all-country-section,
.countries-section,
.country-header,
.countries-header,
.country-name,
.countries-name,
.offer-details,
.countries-offer-details,
.offer-name,
.countries-offer-name,
.partner-program,
.countries-partner-name {
  user-select: none;
}

.partner-section {
  margin-bottom: 20px;
}

.partner-title {
  font-weight: bold;
  cursor: pointer;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
}

.spoiler-symbol,
.countries-spoiler {
  margin-left: 8px;
  font-size: 0.9em;
  color: #8a8d91;

  .countries-title[data-expanded="false"] & {
    color: #e4e6eb;
  }
}

.country-offer-item,
.countries-offer-item {
  display: flex;
  align-items: center;
  padding: 0 12px 0 0;
  cursor: grab;
  user-select: none;
  width: 100%;
  height: 48px;

  box-sizing: border-box;

  transition: background-color 0.2s ease;

  &:last-child {
    border-bottom-left-radius: var(--country-border-radius);
    border-bottom-right-radius: var(--country-border-radius);

    .offer-logo,
    .countries-offer-logo {
      border-bottom-left-radius: var(--country-border-radius);
    }

    .offer-thumbnail-fg,
    .offer-thumbnail-bg,
    .countries-offer-thumbnail-fg,
    .countries-offer-thumbnail-bg {
      border-bottom-left-radius: var(--country-border-radius);
    }
  }

  &:not(:last-child) {
    border-bottom: 1px solid #2c303a;
  }

  &:hover {
    background: #2c303a;
  }

  &:active {
    cursor: grabbing;
    background: #343842;
  }

  &--disabled {
    opacity: 0.6;
    cursor: not-allowed;

    .countries-offer-name {
      font-weight: 300;
    }
  }

  &__section {
    font-size: 0.8rem;
    color: var(--text-secondary-color);
    margin-left: auto;
  }
}

.offer-logo,
.countries-offer-logo {
  position: relative;
  width: 48px;
  height: 48px;
  flex-shrink: 0;
  background-color: #242830;
  margin-right: 10px;
}

.offer-details,
.countries-offer-details {
  display: flex;
  flex-direction: column;
  min-width: 0;
  flex: 1;
  gap: 4px;
}

.offer-name,
.countries-offer-name {
  font-weight: bold;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.partner-program,
.countries-partner-name {
  font-size: 0.9em;
  color: #8a8d91;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.offer-thumbnail-fg,
.offer-thumbnail-bg,
.countries-offer-thumbnail-fg,
.countries-offer-thumbnail-bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 48px !important;
  height: 48px !important;
}

.country-section,
.countries-section {
  margin-bottom: 12px;
  border: 1px solid #2c303a;
  border-radius: var(--country-border-radius);
  overflow: hidden;
  height: max-content;
  width: 100%;
  min-width: 200px;
  height: auto;
  display: flex;
  flex-direction: column;
  background: #1a1d24;

  &[data-expanded="true"] {
    flex: 1;
    min-height: 0;
  }

  .countries-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 8px 0 8px 12px;
    background: #242830;
    cursor: pointer;
    font-weight: bold;
    margin-bottom: 0;
    position: relative;
    z-index: 1;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.15);
    height: 36px;
    box-sizing: border-box;

    &:hover {
      background: #2c303a;
    }

    &[data-expanded="true"] {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
      background: #343842;

      &+.countries-list-container {
        display: flex;
      }
    }
  }

  .countries-header {
    display: flex;
    align-items: center;
    gap: 8px;
  }

  .countries-name {
    font-size: 0.9rem;
    font-weight: 500;
    color: #e4e6eb;
  }

  .countries-spoiler {
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: transform 0.2s ease;
    color: #8a8d91;
    font-size: 16px;
    transform: rotate(-90deg);
    margin-right: -4px;
  }

  .countries-title[data-expanded="true"] {
    .countries-spoiler {
      transform: rotate(0deg);
    }
  }

  .countries-list-container {
    display: none;
    flex-direction: column;
    gap: 1px;
    padding: 1px;
    background: #1a1d24;
    border-top: none;
    overflow-y: auto;
    flex: 1;
    min-height: 0;
    scrollbar-gutter: stable;
  }
}

.country-header,
.countries-header {
  display: flex;
  align-items: center;
  gap: 3px;
}

.country-name,
.countries-name {
  font-size: 1.1em;
}

.country-flag,
.countries-flag {}

.countries-subgroup-header {
  padding: 4px 8px;
  font-size: 12px;
  font-weight: bold;
  color: #e4e6eb;
  background: #242830;
  display: flex;
  align-items: center;
  gap: 8px;

  .offer-indicators {
    display: flex;
    gap: 4px;
    margin-left: auto;
  }

  .offer-indicator {
    width: 6px;
    height: 6px;
    border-radius: 50%;
    background: #8a8d91;
    flex-shrink: 0;

    &--in-section {
      background: #4caf50;
    }

    &--hidden {
      background: #ffb700;
    }
  }
}

.offer-drag-ghost {
  &.active {
    display: flex !important;
  }

  .offer-logo {
    // Custom styles for the ghost logo if needed
  }

  .offer-name {
    // Custom styles for the ghost name if needed
  }
}

.countries-offer-section {
  font-size: 0.8rem;
  color: var(--text-secondary-color);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.header-indicators {
  display: flex;
  gap: 4px;
  margin-left: 12px;
  margin-right: 4px;
  align-items: center;

  .offer-indicator {
    width: 4px;
    height: 4px;
    border-radius: 50%;
    background: #8a8d91;
    flex-shrink: 0;

    &--in-section {
      background: #4caf50;
    }

    &--hidden {
      background: #ffb700;
    }

    &:not(:first-child) {
      position: relative;

      &::before {
        content: '';
        position: absolute;
        left: -3px;
        top: 50%;
        transform: translateY(-50%);
        width: 2px;
        height: 8px;
        background: #2c303a;
      }
    }
  }
}

.offer-item-indicator {
  width: 6px;
  height: 6px;
  border-radius: 50%;
  margin-right: 4px;
  flex-shrink: 0;

  &--in-section {
    background: #4caf50;
  }

  &--hidden {
    background: #ffb700;
  }
}

.countries-list-container {
  overflow-y: auto;

  // Modern scrollbar styling
  scrollbar-width: thin; // Firefox
  scrollbar-color: rgba(255, 255, 255, 0.2) transparent; // Firefox

  // Webkit browsers (Chrome, Safari, Edge)
  &::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background-color: rgba(255, 255, 255, 0.2);
    border-radius: 4px;
    border: 2px solid transparent;
    background-clip: padding-box;

    &:hover {
      background-color: rgba(255, 255, 255, 0.3);
    }
  }

  // Hide scrollbar when not hovering
  &:not(:hover)::-webkit-scrollbar-thumb {
    background-color: rgba(255, 255, 255, 0.1);
  }
}

.section-expand-icon {
  margin-left: auto;
  margin-right: 0;
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #8a8d91;
  transform: rotate(-90deg);
  transition: transform 0.3s ease;
}

.countries-title[data-expanded="true"] {
  .section-expand-icon {
    transform: rotate(0deg);
  }
}

.countries-title[data-expanded="false"] {
  .section-expand-icon {
    transform: rotate(-90deg);
    transition: transform 0.3s ease;
  }
}